import React, { useState, useEffect, forwardRef, useRef } from 'react'
import Header2 from "../Components/Header2";
import axios from "axios";
import { URL } from "../Apiurl";

import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
// import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HistoryIcon from "@mui/icons-material/History";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import GroupIcon from "@mui/icons-material/Group";
import Paper from "@mui/material/Paper";
import { Table, UncontrolledTooltip, Input, Label } from "reactstrap";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import axios from "axios";
import { useNavigate } from 'react-router'
import { Redirect } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
// import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from 'react-select'
import img1 from "../assets/images/24.png";
import img2 from "../assets/images/22.png";
import img3 from "../assets/images/25.png";
// import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import Multiselect from 'multiselect-react-dropdown';
import { useTheme } from '@mui/material/styles';
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import wetrain from "../assets2/icon/we_train_best_bg.jpg";
import styled, { createGlobalStyle } from "styled-components";
import logo from "../assets/images/logo_IGI.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const GlobalStyle = createGlobalStyle`
  body {
    background-color: #7f8fa6;
    color: white;
  }
`;
const Toggle = styled.button`
  width: 100px;
  height: 50px;
  position: relative;
  cursor: pointer;
  border-radius: 25px;
  outline: none;
  background-color: ${(props) => (props.on ? "#8c7967" : "#353b48")};
  border: 3px solid white;

  &::after {
    content: "";
    position: absolute;
    top: 3.5px;
    will-change: transform;
    transform: translate(${(props) => (props.on ? 5.5 : -44)}px);
    transition: transform 0.2s ease-out;
    width: 35px;
    height: 35px;
    background: white;
    border: 1px solid #7f8fa6;
    outline: none;
    border-radius: 50%;
  }
`;


const ComponentToPrint = forwardRef((props, ref) => {
    return <div ref={ref}>
        <div>

        </div>
    </div>;
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

function Settings() {
    const ref = useRef();
    const [details, setdetails] = useState([]);
    const [ondetails, setondetails] = useState([]);
    const [find, setfind] = useState([]);
    const [show, setShow] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState();
    const [on, toggle] = useState(false);
    const [data, setdata] = useState([])



    const handlechange = (e) => {
        let myUser = { ...find };
        myUser[e.target.name] = e.target.value;
        setfind(myUser);
        console.log(myUser)
    }
    const ehandlechange = (e) => {
        let myUser = { ...data };
        myUser[e.target.name] = e.target.value;
        setdata(myUser);
    }

    useEffect(() => {
        getAllAddDetails();
        getsetData();
    }, []);

    const getAllAddDetails = () => {
        var token = sessionStorage.getItem("token")
        axios.get(URL.getcards, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setdetails(res.data.summariesResult)
            // setPersonName(res.data.summariesResult)
        })
    }

    const optionList = details.map((response) => ({
        value: response._id,
        label: response.summaryNumber
    }));

    function handleSelect(details) {
        setSelectedOptions(details);
        console.log(details)
    }

    //oprations...

    const getsetData = () => {
        var token = sessionStorage.getItem("token")
        axios.post(URL.getsetdata, {}, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setondetails(res.data.showSetting)
        })
    }

    const settingData = () => {
        var token = sessionStorage.getItem("token");
        var id = ondetails._id;
        var params = {
            "isUnderMaintanance": data.isUnderMaintanance,

        }
        axios.put(URL.updatesetdata + "/" + id, params, {
            // headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' },
            headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
            if (res.status === 200) {
                console.log("upaded succes")
                toast("Website Updated");
                // handleClose1()
                // getAllAddDetails()
                getsetData()
            }
        },
              (error) => {
                if (error.response && error.response.status === 400) {
                  toast(error.response.data.message);
                }
              }

        )
    }




    return (

        <div style={{ background: "#ebeaea" }}>
            {" "}
            <Box sx={{ display: "flex" }} className="cardmrg">
                <Drawer></Drawer>
                <CssBaseline />
                <Box component="main" style={{ background: "#ebeaea" }} sx={{ flexGrow: 2, p: 4 }}>
                    {/* <DrawerHeader /> */}

                    <div style={{ padding: "10px" }}>
                        <div className=''>
                            <div className='d-flex justify-content-center'>
                                <div style={{ padding: "25px 0px 25px 0px" }}>
                                    <Card className="cardstyle ">
                                        {/* <div style={{ padding: "50px" }}> */}
                                        {/* <h6>Print Cards</h6><br />
                                   sfdgfdhg */}
                                        <div style={{ padding: "30px" }}>
                                            <div className="d-flex justify-content-center mt-5 mb-4" >
                                                <img src={logo} style={{ width: "50%", }} />
                                            </div>
                                            {/* <p className='text-center' >IGI grades the widest variety of gemstones and jewelry in more corners<br/> of the world than any other gemological organization.</p> */}
                                            <p className='text-center mt-4 text-dark' >As the global leader for jewelry assessment our experience and reach
                                                are unrivaled.<br /> Renowned for diamonds and colored gemstones, we are equally trusted <br />with
                                                the grading of pearls, exotic carvings, fine jewelry pieces<br /> and modern synthetics and simulants.</p>

                                            <div style={{ padding: "20px" }}>
                                                <div className="row">
                                                    {/* <GlobalStyle /> */}
                                                    {/* <Toggle on={on} onClick={() => toggle(!on)} /> */}


                                                    <div className='col-6 offset-2'>
                                                        <label>Under Maintenance<span className='ml-1' style={{color:"red"}}>*</span></label>
                                                        <select className="form-control" name="isUnderMaintanance" onChange={(e) => { ehandlechange(e); }} required>
                                                            {/* <option value="" >Select</option> */}
                                                            <option value="true" >No</option>
                                                            <option value="false" >Yes</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-2'>
                                                        <button style={{marginTop:"30px"}} className='btn btn-primary' onClick={settingData}>Submit</button>

                                                        {/* <button className='btn btn-primary'>Active</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>

                    </div>


                </Box>
            </Box>
            <ToastContainer />
        </div >



    )
}

// {details.map((d) => {
//     return <option value={d._id}>{d.sumid}</option>;
// })}


export default Settings