import React from 'react'
import error from "../assets/images/errorunder.png";
// import GifPlayer from "react-gif-player";
// var GifPlayer = require('react-gif-player');
import error1 from "../assets/images/error1.gif";

function Error() {
  return (
    <div>
       <div className='d-flex justify-content-center mb-5'>
       <img src={error1} className="errorimg" />
     
       </div>
      
    </div>
  )
}

export default Error