import React, { useState } from 'react'
import "../assets/css/form.css"
import { Link, NavLink } from 'react-router-dom';
// import logo from "../assets/img/logo/logo.png";
import logo from "../assets/images/logo_IGI.png";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router'

const Forgotepsw = () => {

    // const [show, setshow] = useState(true)
    // const [show1, setshow1] = useState(true)
    const [data, setdata] = useState([])
    let navigate = useNavigate();


    const handleChange = (e) => {
        let newadmin = { ...data };
        newadmin[e.target.name] = e.target.value;
        setdata(newadmin);
      };

    const Forgot = () => {
        // var token = sessionStorage.getItem("token");
        var params = {
            "email": data.email,
        }
        axios.post("http://103.180.121.214:3005/api/admin/jsr/profile/settings/generateotp", params).then((res) => {
            if (res.status === 200) {
                console.log(res.data)
                // sessionStorage.setItem("LoginId", res.data.LoginId._id)
                // sessionStorage.setItem("email", res.data.user.email)
                // sessionStorage.setItem("role", res.data.user.role)
                // sessionStorage.setItem("token", res.data.token)
                // navigate('/Otp')
            }
        },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }
        )

    }

    return (
        < >
            <div class="section1">
                <div class="container1" id="container1" style={{ width: "400px" }}>
                    <div class="container">
                        <form  style={{marginTop:"40px"}}>
                            <a href="#"><NavLink to=""><img src={logo} alt="logo"  className='limg ' /></NavLink></a>
                            <h2 className='mt-3 mb-3'>Forgot Password</h2>
                            <input type="email" name="email"  value={data.email} onChange={(e) => {handleChange(e);}} placeholder="Email Or Mobile"  />
                            <span class="remember mt-3"> Are you Remember The Password <a class="forgot" > <NavLink to="/AdminLogin">Log In</NavLink></a></span>
                            <button type='button' onClick={Forgot}>Submit</button>

                        </form>
                    </div>
                    {/* <div class="form-container1 sign-up-container1">
                       <form>
                           <a href="#"><NavLink to="/"><img src={logo} alt="logo"/></NavLink></a>
                           <h2>OTP Verfication</h2>
                           <p>Please enter the one time password to verify your account</p>
                           <p>A code has been sent to *******9897</p>
                           <a href="#">Change the Mobile Number</a>
                           <div class="validate_inputs">
                               <input type="otp-1" />
                               <input type="otp-2" />
                               <input type="otp-3" />
                               <input type="otp-4" />
                               <input type="otp-5" />
                               <input type="otp-6" />
                           </div>
                           <a href="#">Resend OTP</a>
                           <button onclick="return false;">Validate</button>
                       </form>
                   </div>   
                    <div class="overlay-container1">
                        <div class="overlay">
                            <div class="overlay-panel overlay-left">
                                <h1>Welcome Back!</h1>
                                <button class="ghost" id="signIn">Forgot Password</button>
                            </div>
                            <div class="overlay-panel overlay-right">
                                <h1>Hello, Friend!</h1>
                                <p>Enter your personal details and start your journey with us</p>
                                <button class="ghost" id="signUp" onClick={()=>setshow(true)}>OTP Validate </button>

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Forgotepsw