import React, { useState, useEffect, forwardRef, useRef } from 'react'
import Header2 from "../Components/Header2";
import axios from "axios";
import { URL } from "../Apiurl";

import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HistoryIcon from "@mui/icons-material/History";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import GroupIcon from "@mui/icons-material/Group";
import Paper from "@mui/material/Paper";
import { Table, UncontrolledTooltip, Input, Label } from "reactstrap";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import axios from "axios";
import { useNavigate } from 'react-router'
import { Redirect } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
// import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from 'react-select'
import img1 from "../assets/images/24.png";
import img2 from "../assets/images/22.png";
import img3 from "../assets/images/25.png";
// import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import Multiselect from 'multiselect-react-dropdown';
import { useTheme } from '@mui/material/styles';
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
// import { Multiselect } from "multiselect-react-dropdown";
import whitelogo from "../assets/images/loading.png";
import { height } from '@mui/system';
import { saveAs } from 'file-saver'



const ComponentToPrint = forwardRef((props, ref) => {
    return <div ref={ref}>
        <div>
            <Card className="cardstyle">
                {/* <div style={{ padding: "50px" }}> */}
                {/* <h6>Print Cards</h6><br />
                                   sfdgfdhg */}
                {/* <div style={{ padding: "20px" }}>
                    <div className='row' >
                        <div className='col'>
                            <img src={img1} style={{ width: "100%" }}></img>
                        </div>
                        <div className='col'>
                            <img src={img2} style={{ width: "100%" }}></img>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <img src={img3} style={{ width: "100%" }}></img>
                        </div>
                        <div className='col'>
                            <img src={img1} style={{ width: "100%" }}></img>
                        </div>
                    </div>
                    <div className='row' >
                        <div className='col'>
                            <img src={img1} style={{ width: "100%" }}></img>
                        </div>
                        <div className='col'>
                            <img src={img2} style={{ width: "100%" }}></img>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <img src={img3} style={{ width: "100%" }}></img>
                        </div>
                        <div className='col'>
                            <img src={img1} style={{ width: "100%" }}></img>
                        </div>
                    </div>
                </div> */}


                 {/* {users.map((d) => {
    return <div >{d.image}</div>;
})} */}


                {/* </div> */}
            </Card>
        </div>
    </div>;
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

function Cardprint() {

    const [spinner, setSpinner] = useState(false);
    const [sticky, setSticky] = useState("");
    const [visible, setvisible] = useState("");

    const ref = useRef();
    const [details, setdetails] = useState([]);
    const [find, setfind] = useState([]);
    const [show, setShow] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState();


    const handlechange = (e) => {
        let myUser = { ...find };
        myUser[e.target.name] = e.target.value;
        setfind(myUser);
        console.log(myUser)
    }

    useEffect(() => {
        getAllAddDetails();
      
    }, []);

    const loadimg =()=>{
        setSpinner(true);
        setTimeout(() => {
            setSpinner(false);
        }, 6000);


        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }
    const isSticky = () => {
        // const clik=onClick
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 250 ? "logo-sm" : "";
        setSticky(stickyClass);
        console.log(stickyClass);
        const visible = scrollTop >= 250 ? "" : "-800px";
        // const leftside = clik >= 250 ? "" : "slideLeft";
        // setlefts(leftside)
        setvisible(visible);
    };

    const downloadImage = () => {
        saveAs("https://api.igi-org.in/"+ users.image) // Put your image url here.
      }

   

    const getAllAddDetails = () => {
        var token = sessionStorage.getItem("token")
        axios.get(URL.getcards, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setdetails(res.data.summariesResult)
            // setPersonName(res.data.summariesResult)
        })
    }

    const optionList = details.map((response) => ({
        value: response._id,
        label: response.summaryNumber
    }));

    // const datalabel=details.label


    function handleSelect(details) {
        setSelectedOptions(details);
        console.log(details)
    }

    const [users, setusers] = useState([]);
    const handleSubmit = (e) => {
        e.preventDefault();
        printcards();
        loadimg()
      };
    
      const printcards = () => {
        const data = { summaryNos: selectedOptions };
        console.log(data)
    
        axios
          .post(URL.a4sizeshhet,data, {})
          .then((res) => {
            setusers(res.data);
          });
      };

    return (
        <>

        {spinner && (
            <div className='loadingstyle mobload span'>
                <img src={whitelogo} className="img-fluid mx-auto d-block footer_logo imgmar bounce" />

                <h5 className='text-white' style={{ marginTop: "-25px", fontSize: "20px" }}>Loading <span
                    data-text=" ..." style={{ letterSpacing: "2px" }}> </span></h5>
            </div>
        )}

        <div style={{ background: "#ebeaea" }}>
            {" "}
            <Box sx={{ display: "flex" }} className="cardmrg">
                <Drawer></Drawer>
                <CssBaseline />
                <Box component="main" style={{ background: "#ebeaea" }} sx={{ flexGrow: 2, p: 4 }}>
                    {/* <DrawerHeader /> */}

                    <div style={{ padding: "10px" }}>
                        <div className='row'>
                            <div className='col-sm-5'>
                                <Card className="cardstyle">
                                    <div style={{ padding: "50px" }}>
                                        <h6>Print Cards1</h6><br />
                                        <form onSubmit={(e) => {handleSubmit(e);}}>
<span style={{fontSize:"10px",color:"red"}}>Maximum 4 summary Number*</span>
                                        <Select
                                            style={{ width: "100%" }}
                                            options={optionList}
                                            placeholder="Select color"
                                            value={selectedOptions}
                                            // onChange={handleSelect}
                                            isSearchable={true}
                                            isMulti
                                            selectionLimit="2"
                                            onChange={(v) => v.length < 4 ? handleSelect(v): null}
                                            // className='basic-multi-select'
                                            // classNamePrefix='select'
                                        />
                                        



                                        <button   className='btn form-control mt-3 bg-dark text-white'>Preview</button>
                                        </form>
                                    </div>


                                    {/* <Multiselect
                                        isObject={false}
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={(event) => { console.log(event) }}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={(event) => { console.log(event) }}
                                    options={[
                                        'Option 1',
                                        'Option 2',
                                        'Option 3',
                                        'Option 4',
                                        'Option 5'
                                      ]}
                                    /> */}




                                    {/* <select isMulti className='form-control'>
                                        {details.map((d) => {
                                            return <option value={d._id}>{d.summaryNumber}</option>;
                                        })}
                                    </select> */}
                                </Card>
                            </div>
                            <div className='col-sm-7'>
                                <div>
                                    {/* <ReactToPrint
                                        trigger={() => <a href="#">Print this out!</a>}
                                        content={() =>componentRef}
                                    />
                                    <ComponentToPrint ref={el => (componentRef = el)} /> */}

                                    {/* <ReactToPrint content={() => ref.current}>
                                        <ComponentToPrint ref={ref} />
                                        <PrintContextConsumer>
                                            {({ handlePrint }) => (
                                                // <button onClick={handlePrint}>Print this out!</button>
                                                <div style={{ float: "right" }} className="mt-4">
                                                    <button onClick={handlePrint} className='btn btn-primary' >
                                                        Download
                                                    </button>
                                                </div>
                                            )}
                                        </PrintContextConsumer>
                                    </ReactToPrint> */}
                              <Card className="cardstyle" style={{padding:"5px"}} >
     
                                     <div>
                          <div><img  src={"https://api.igi-org.in/"+ users.image } alt=""  style={{width:"100%"}} /></div>

                             <div style={{float:"right"}}>
                           
<button  className='btn btn-primary bg-primary text-white mt-3' onClick={downloadImage}  ><i class="fa fa-arrow-circle-o-down mr-1" aria-hidden="true" ></i> Download </button>
</div>
</div>
</Card>


                                </div>
                            </div>
                        </div>

                    </div>


                </Box>
            </Box>
        </div >
        </>



    )
}

// {details.map((d) => {
//     return <option value={d._id}>{d.sumid}</option>;
// })}


export default Cardprint











// import React, { useState, useEffect, forwardRef, useRef } from "react";
// import axios from "axios";
// import { URL } from "../Apiurl";
// import Drawer from "./Drawers";
// import Box from "@mui/material/Box";
// import { styled } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import { Row, Col, Card, CardBody, Media } from "reactstrap";
// // import Select from "react-select";
// import img1 from "../assets/images/24.png";
// import img2 from "../assets/images/22.png";
// import img3 from "../assets/images/25.png";
// import ReactToPrint, { PrintContextConsumer } from "react-to-print";

// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
// import Chip from "@mui/material/Chip";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import { useTheme } from "@mui/material/styles";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

// const ComponentToPrint = forwardRef((props, ref) => {
  
//   return (
//     <div ref={ref}>
//       <div>
//         <Card className="cardstyle">
//           <div style={{ padding: "20px" }}>
//             <div className="row">
//               <div className="col">
//                 <img src={img1} style={{ width: "100%" }}></img>
//               </div>
//               <div className="col">
//                 <img src={img2} style={{ width: "100%" }}></img>
//               </div>
//             </div>
//             <div className="row mt-3">
//               <div className="col">
//                 <img src={img3} style={{ width: "100%" }}></img>
//               </div>
//               <div className="col">
//                 <img src={img1} style={{ width: "100%" }}></img>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col">
//                 <img src={img1} style={{ width: "100%" }}></img>
//               </div>
//               <div className="col">
//                 <img src={img2} style={{ width: "100%" }}></img>
//               </div>
//             </div>
//             <div className="row mt-3">
//               <div className="col">
//                 <img src={img3} style={{ width: "100%" }}></img>
//               </div>
//               <div className="col">
//                 <img src={img1} style={{ width: "100%" }}></img>
//               </div>
//             </div>
//           </div>
//         </Card>
//       </div>
//     </div>
//   );
// });

// function Cardprint() {
//   const theme = useTheme();
//   const ref = useRef();
//   const [details, setdetails] = useState([]);
//   const [selectedOptions, setSelectedOptions] = useState();

//   useEffect(() => {
//     getAllAddDetails();
//   }, []);

//   const getAllAddDetails = () => {
//     var token = sessionStorage.getItem("token");
//     axios
//       .get(URL.getcards, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((res) => {
//         console.log(res.data);
//         setdetails(res.data.summariesResult);
//       });
//   };

//   const optionList = details.map((response) => ({
//     value: response._id,
//     label: response.summaryNumber,
//   }));

//   function handleSelect(details) {
//     setSelectedOptions(details);
//     console.log(details.value);
//   }

//   const [users, setusers] = useState([]);

//   console.log(users);

//   const [personName, setPersonName] = useState([ ]);

//   console.log(personName);

//   const handleChanges = (event) => {
//     // setSelectedOption(event);
//     const {
//       target: { value },
//     } = event;
//     setPersonName(typeof value === "string" ? value.split(",") : value);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     data();
//   };

//   const data = () => {
//     const data = { summaryNos: personName };
//     console.log(data)

//     axios
//       .post("http://103.171.181.73:5011/api/a4sizeshhet", data, {})
//       .then((res) => {
//         setusers(res.data.summariesResult);
//       });
//   };

//   const maxOptions = 5;
//     const [selectedOption, setSelectedOption] = useState([]);
//     // const handleTypeSelect = e => {
//     //     setSelectedOption(e);
//     // };

  
//   return (
//     <div style={{ background: "#ebeaea" }}>
//       {" "}
//       <Box sx={{ display: "flex" }} className="cardmrg">
//         <Drawer></Drawer>
//         <CssBaseline />
//         <Box
//           component="main"
//           style={{ background: "#ebeaea" }}
//           sx={{ flexGrow: 2, p: 4 }}
//         >
//           <div style={{ padding: "10px" }}>
//             <div className="row">
//               <div className="col-5">
//                 <Card className="cardstyle">
//                   <div style={{ padding: "50px" }}>
//                     <form
//                       method="post"
//                       onSubmit={(e) => {
//                         handleSubmit(e);
//                       }}
//                     >
//                       <h6>Print Cards</h6>
//                       <br />

//                       {/* <Select
//                         style={{ width: "100%" }}
//                         options={optionList}
//                         placeholder="Select color"
//                         value={selectedOptions}
//                         onChange={handleSelect}
//                         isSearchable={true}
//                         isMulti
//                         maxLength="7"
//                       /> */}

//                       {/* <FormControl style={{ width: "100%" }}>
//                         <InputLabel id="demo-multiple-checkbox-label">
//                           state
//                         </InputLabel>
//                         <Select
//                           labelId="demo-multiple-chip-label"
//                           id="demo-multiple-chip"
//                           multiple
//                           name="courses"
//                           value={personName}
//                           onChange={handleChanges}
//                           input={
//                             <OutlinedInput
//                               id="select-multiple-chip"
//                               label="Chip"
//                               style={{
//                                 background: "white",
//                               }}
//                             />
//                           }
//                           renderValue={(selected) => {
//                             return (
//                               <>
//                                 {selected.map((value, o) => {
//                                   const option = details.find(
//                                     (o) => o._id === value
//                                   );
//                                   return (
//                                     <Chip key={value} label={option.summaryNumber} />
//                                   );
//                                 })}
//                               </>
//                             );
//                           }}
//                           MenuProps={MenuProps}
//                         >
//                           {details.map((name) => (
//                             <MenuItem
//                               key={name._id}
//                               value={name._id}
//                               style={getStyles(name.summaryNumber, personName, theme)}
//                             >
//                               {name.summaryNumber}
//                             </MenuItem>
//                           ))}
//                         </Select>
//                       </FormControl> */}

//                       <FormControl sx={{ m: 1, width: 300 }}>
//                         <InputLabel id="demo-multiple-chip-label">
//                         Summary Number
//                         </InputLabel>
//                         <Select
//                           labelId="demo-multiple-chip-label"
//                           id="demo-multiple-chip"
//                           multiple
//                           value={personName}
//                         //   maxItems={1}
//                           onChange={handleChanges}
//                         //   onChange={(v) => v.length < 9 ? handleChanges(v): null}
//                         //   options={selectedOption.length === maxOptions ? [] : data}
//                         //   noOptionsMessage={() => {
//                         //       return selectedOption.length === maxOptions
//                         //           ? 'You have reached the max options value'
//                         //           : 'No options available';
//                         //   }}
//                         //   selectionLimit="2"
//                         //   onChange={(e) => e.length < 4 ? handleChanges(e): null}
//                         //   onChange={(event) => event.length < 4 ? handleChanges(event): null}

//                           input={
//                             <OutlinedInput
//                               id="select-multiple-chip"
//                               label="Chip"
//                             />
//                           }
//                           renderValue={(selected) => (
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 flexWrap: "wrap",
//                                 gap: 0.5,
//                               }}
//                             >
//                               {selected.map((value) => (
//                                 <Chip key={value} label={value}  />
//                               ))}
//                             </Box>
//                           )}
//                           MenuProps={MenuProps}
//                         >
//                           {details.map((name, i) => (
//                             <MenuItem
//                               key={i}
//                               value={name.summaryNumber}
//                               style={getStyles(
//                                 name.summaryNumber,
//                                 personName,
//                                 theme
//                               )}
//                             >
//                               {name.summaryNumber}
//                             </MenuItem>
//                           ))}
//                         </Select>
//                       </FormControl>

//                       <button
//                         className="btn form-control mt-3 bg-dark text-white"
//                         type="submit"
//                       >
//                         Preview
//                       </button>
//                     </form>
//                   </div>
//                 </Card>
//               </div>
//               <div className="col-7">
//                 <div>
//                   <ReactToPrint content={() => ref.current}>
//                     <ComponentToPrint ref={ref} />
//                     <PrintContextConsumer>
//                       {({ handlePrint }) => (
//                         <div style={{ float: "right" }} className="mt-2">
//                           <button
//                             onClick={handlePrint}
//                             className="btn btn-primary"
//                           >
//                             Download
//                           </button>
//                         </div>
//                       )}
//                     </PrintContextConsumer>
//                   </ReactToPrint>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Box>
//       </Box>
//     </div>
//   );
// }

// export default Cardprint;