import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { Link, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Row,
  Col,
  Card,
  CardBody,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // Modal,
  Button,
  Input,
  Label,
  Form,
  CardHeader,
} from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Table, UncontrolledTooltip } from "reactstrap";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import add1 from "../assets/img/new/add1.jpg";
// import img2 from "../assets/img/new/img2.jpg";
// import img3 from "../assets/img/new/img3.jpg";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from "react-paginate";
import Checkbox from '@mui/material/Checkbox';
import download from "../assets/images/download.png";
import qrcode from "../assets/images/qrcode.png";
import { height } from "@mui/system";
import { URL } from "../Apiurl";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Admins = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // const [show2, setShow2] = useState(false);

  // const handleClose2 = () => setShow2(false);
  // const handleShow2 = () => setShow2(true);



  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const [admin, setadmins] = useState([]);
  const [file, setFile] = useState()
  const [data, setdata] = useState([])
  const [search, setsearch] = useState("");
  const [state, setstate] = useState([])
  const [district, setdistrict] = useState([])
  const [cities, setcity] = useState([])
  const [village, setvillages] = useState([])
  const [form, setform] = useState([])

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = admin.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(admin.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };



  // add
  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  }

  // edit
  const ehandlechange = (e) => {
    let myUser = { ...data };
    myUser[e.target.name] = e.target.value;
    setdata(myUser);
  }

  function FilehandleChange(event) {
    setFile(event.target.files[0])
  }


  useEffect(() => {
    getAllAddDetails();
    // districts()
    // getAllStates()
    // city()
    // villages()
    // getAllDepartment();
  }, []);

  const getAllAddDetails = () => {
    var token = sessionStorage.getItem("token")
    axios.get(URL.getadmins, {
      headers: { Authorization: `Bearer ${token}` }
    }).then((res) => {
      console.log(res.data)
      setadmins(res.data.usersResult)
    })
  }

  const addAdmin = () => {
    var token = sessionStorage.getItem("token");
    var params = {
      "username": form.username,
      "email": form.email,
      "contactNumber": form.contactNumber,
      "password": form.password,
      "status": form.status,
    }

    axios.post(URL.addadmin, params, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      // if (res.status === 200) {
      console.log("success")
      toast("Added Successfully ");
      getAllAddDetails()
      handleClose();
      // toggle();
      // }
    })

  }

  const adminSubmit = (e) => {
    e.preventDefault();
    addAdmin();
  };

  const adminEdit = (e) => {
    e.preventDefault();
    editAdmin();
  };

  const editAdmin = () => {
    var token = sessionStorage.getItem("token");
    var id = data._id;
    var params = {
      "username": data.username,
      "email": data.email,
      "contactNumber": data.contactNumber,
      // "password":data.password,
      "status": data.status,
    }
    axios.put(URL.editadmin + "/" + id, params, {
      // headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' },
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.status === 200) {
        console.log("upaded succes")
        toast("Updated successfully ");
        handleClose1()
        getAllAddDetails()
      }
    },
      (error) => {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message);
        }
      }

    )
  }

  const getpopup = (data) => {
    setdata(data);
    handleShow1()
  };

  const deleteTemples = (summariesResult) => {
    var token = sessionStorage.getItem("token");
    axios.delete(URL.deleteadmin + "/" + summariesResult, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.status === 200) {
        getAllAddDetails()
        toast("Deleted successfully");
      }
    })

  }

  const manageDelete = (summariesResult) => {
    const confirmBox = window.confirm("Do you want to Delete?");
    if (confirmBox === true) {
      deleteTemples(summariesResult);
    }
  };





  return (
    <div >
      {" "}
      <Box sx={{ display: "flex" }} style={{ background: "#ebeaea" }} className="cardmrg" >
        <Drawer></Drawer>
        <CssBaseline />
        <Box component="main" style={{ background: "#ebeaea" }} sx={{ flexGrow: 2, p: 4 }}>
          {/* <DrawerHeader /> */}
          <Row>
            <Col lg={12}>
              <Card className="cardstyle">
                <CardHeader className="cardheader">
                  <div className="row">
                    <div className="col">
                      <h4 className="cardh4"><b>Admin Users</b></h4>
                    </div>
                    <div className="col ">
                      <Button
                        type="button"
                        className="btn pdf-view-btn addbtn"
                        // style={{ float: "right", marginTop: "34px" }}
                        onClick={handleShow}
                      >
                        <i class="fa fa-plus mr-1" aria-hidden="true"></i> Add Admins
                      </Button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody>

                  {/* <div className="row container mt-3"> */}
                  {/* <div className="col mt-4"> */}
                  <div style={{ float: "right" }}>
                    <input
                      type="search"
                      placeholder="search..."
                      className="form-control mb-3"
                      style={{ width: "100%" }}
                      onChange={(e) => { setsearch(e.target.value) }}
                    />
                  </div>

                  {/* </div> */}
                  {/* <div className="col ">
                      <Button
                        type="button"
                        className="btn pdf-view-btn addbtn"
                        onClick={handleShow}
                      >
                        <i class="fa fa-plus mr-1" aria-hidden="true"></i> Add Data
                      </Button>
                    </div> */}
                  {/* </div> */}
                  <div className="table-responsive mt-3">
                    <Table
                      bordered
                      className="table-centered datatable dt-responsive nowrap "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead
                        className="thead-light"
                        style={{ background: "#1562a0", color: "white" }}
                      >
                        <tr>
                          <th>Sl.no</th>
                          <th>Name</th>
                          {/* <th>Description</th> */}
                          <th>Contact</th>
                          <th>Email</th>
                          <th>Status</th>
                          {/* <th>Clarity</th> */}
                          {/* <th>Image</th>
                          <th>Qr Code</th> */}

                          <th style={{ width: "110px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists
                          .filter((usersResult) => {
                            if (search === ! null) {
                              return usersResult
                            }
                            else if (usersResult.username.toUpperCase().includes(search.toUpperCase())) {
                              return usersResult
                            }

                          })
                          .map((usersResult, key) => (
                            <tr key={key}>
                              <td>{(pageNumber - 1) * 10 + key + 11}</td>
                              <td>{usersResult.username}</td>
                              <td>{usersResult.contactNumber}</td>
                              <td>{usersResult.email}</td>
                              <td>{usersResult.status === true || usersResult.status == "true"
                                ? "Active"
                                : "Inactive"}{" "}
                              </td>

                              {/* <td>{summariesResult.totalEstWt}</td>
                              <td>{summariesResult.color}</td>
                              <td>{summariesResult.clarity}</td>
                              <td><img src={"http://103.171.181.73:5011/" + summariesResult.image} style={{ width: "100px", height: "90px" }} /></td>

                              <td onClick={handleShow2}> <img src={"http://103.171.181.73:5011/" + summariesResult.qrcode} style={{ width: "100px", height: "90px" }} /></td> */}
                              <td>
                                {/* <Link
                                  to="#"
                                  className="mr-3 text-success"
                                  id={"edit" + key}
                                  onClick={() => { getpopup(usersResult) }}
                                >
                                  <ModeEditIcon />
                                </Link>
                                <UncontrolledTooltip
                                  target={"edit" + key}
                                  placement="top"
                                >
                                  Edit
                                </UncontrolledTooltip>
                               
                                <Link
                                  to="#"
                                  className="text-danger"
                                  id={"delete" + key}
                                  onClick={() => { manageDelete(usersResult._id) }}
                                >
                                  <DeleteOutlineIcon></DeleteOutlineIcon>
                                </Link>
                                <UncontrolledTooltip
                                  target={"delete" + key}
                                  placement="top"
                                >
                                  Delete
                                </UncontrolledTooltip> */}

                                <div className="mt-3">
                                <span className="adminbtnbad"><a className="btn adminbtns" onClick={() => { getpopup(usersResult) }}> <ModeEditIcon className="text-success" /></a></span>
                                <span className="adminbtnbad"><a className="btn adminbtns3" onClick={() => { manageDelete(usersResult._id) }}> <DeleteOutlineIcon className="text-danger" /></a></span>
                                </div>
                              </td>

                            </tr>
                          ))}
                      </tbody>
                    </Table>

                    {/* <div style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                      />
                    </div> */}
                    <div className="mt-3" style={{ float: "right" }}>
                      {/* <Stack spacing={2}> */}
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                      {/* </Stack> */}
                    </div>




                    <Modal show={show} onHide={handleClose} className="modalpad" >
                      <Modal.Header  >
                        <Modal.Title>Add Admins </Modal.Title><a onClick={handleClose}> <i class="fa fa-times cros" aria-hidden="true"></i></a>

                      </Modal.Header>
                      <Modal.Body className="modalbod">
                        <form
                          onSubmit={adminSubmit}
                        >

                          <Label>User Name :</Label>
                          <Input type="text"  pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="User Name" className="form-control" name="username" onChange={(e) => { handlechange(e); }} required />

                          <Label className="mt-2">Contact:</Label>
                          <Input type="text" maxlength="10" pattern="\d{10}" placeholder="Contact" className="form-control" name="contactNumber" onChange={(e) => { handlechange(e); }} required />

                          <Label className="mt-2">Email :</Label>
                          <Input type="email" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" placeholder="Email" className="form-control" name="email" onChange={(e) => { handlechange(e); }} required />
                          <Label className="mt-2">Password :</Label>
                          <Input type="text" placeholder="Password" className="form-control" name="password" onChange={(e) => { handlechange(e); }} required />
                          <Label className="mt-2">Status :</Label>
                          <select className="form-control" name="status" onChange={(e) => { handlechange(e); }} required>
                            <option value="" >Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Inactive</option>
                          </select>
                          <div style={{ float: "right" }} className="mt-3">
                            <Button className="mr-3 bg-danger" onClick={handleClose}>
                              Cancel
                            </Button>
                            <Button color="success" type="submit">
                              Submit
                            </Button>
                          </div>


                        </form>
                      </Modal.Body>
                    </Modal>

                    <Modal show={show1} onHide={handleClose1} className="modalpad" >
                      <Modal.Header  >
                        <Modal.Title>Edit Admins </Modal.Title><a onClick={handleClose1}> <i class="fa fa-times cros" aria-hidden="true"></i></a>

                      </Modal.Header>
                      <Modal.Body className="modalbod">
                        <form onSubmit={adminEdit}>

                          <Label>User Name :</Label>
                          <Input type="text"  pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="User Name" value={data.username} className="form-control" name="username" onChange={(e) => { ehandlechange(e); }} required />

                          <Label className="mt-2">Contact:</Label>
                          <Input type="text" maxlength="10" pattern="\d{10}" placeholder="Contact" className="form-control" value={data.contactNumber} name="contactNumber" onChange={(e) => { ehandlechange(e); }} required />

                          <Label className="mt-2">Email :</Label>
                          <Input type="email"  pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" placeholder="Email" className="form-control" value={data.email} name="email" onChange={(e) => { ehandlechange(e); }} required />
                          {/* <Label className="mt-2">Password :</Label>
                              <Input type="text" placeholder="Password" className="form-control" value={data.password} name="password" onChange={(e) => { ehandlechange(e); }} required /> */}
                          <Label className="mt-2">Status :</Label>
                          <select className="form-control" value={data.status} name="status" onChange={(e) => { ehandlechange(e); }} required>
                            <option value="" >Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Inactive</option>
                          </select>
                          <div style={{ float: "right" }} className="mt-3">
                            <Button className="mr-3 bg-danger" onClick={handleClose1}>
                              Cancel
                            </Button>
                            <Button color="success" type="submit" >
                              Submit
                            </Button>
                          </div>


                        </form>
                      </Modal.Body>
                    </Modal>


                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Paper elevation={3}></Paper> */}
        </Box>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default Admins;
