import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { Link, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import { Row, Col, Card, CardBody } from "reactstrap";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Table, UncontrolledTooltip } from "reactstrap";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
    Row,
    Col,
    Card,
    CardBody,
    // ModalHeader,
    // ModalBody,
    // ModalFooter,
    // Modal,
    // Button,
    Input,
    Label,
    Form,
} from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from "react-paginate";
import { Stack } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorIcon from '@mui/icons-material/Error';
import HomeIcon from '@mui/icons-material/Home';
// import logo from "../assets/img/logo/logo.png";
// import backgroundimg from "../assets/img/donation/6.jpg";
import logo from "../assets/images/logo_IGI.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { URL } from "../Apiurl";


const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));


function Profile() {
    const textInput = React.useRef();
    const textInput1 = React.useRef();
    const textInput2 = React.useRef();

    const clearInput = () => {
        (textInput.current.value = "");
        (textInput1.current.value = "");
        (textInput2.current.value = "");
    }

    const [show, setShow] = useState(false);
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [profiles, setprofiles] = useState([])
    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal2(!modal2);
    const [data, setdata] = useState([])
    const [form, setform] = useState("");

    const ehandlechange = (e) => {
        let myUser = { ...data };
        myUser[e.target.name] = e.target.value;
        setdata(myUser);
    }


    useEffect(() => {
        adminProfile();

    }, []);


    const adminProfile = () => {
        var token = sessionStorage.getItem("token");
        var _id = sessionStorage.getItem("AdminId")
        // var params={
        //      "AdminId":sessionStorage.getItem("AdminId")
        // }
        axios.post(URL.adminprofile, { _id }, {
            headers: { Authorization: `Bearer ${token}` },
            // "_id":sessionStorage.getItem("AdminId")
        }).then((res) => {
            console.log(res.data)
            setprofiles(res.data.ProfileResult)
            //   toast("Successfully Added");
            //   getAllUsers()
            //   toggle();
            // }
        })

    }

    const editProfile = () => {
        var token = sessionStorage.getItem("token");
        var id = data._id;
        var params = {
            // "id": data._id,
            "username": data.username,
            "email": data.email,
            "contactNumber": data.contactNumber,
        }
        console.log(params)
        axios.put(URL.admineditprofile + "/" + id, params, {
            headers: { Authorization: `Bearer ${token}` },
            // headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' },
        }).then((res) => {
            if (res.status === 200) {
                console.log("upaded successfuly")
                toast("Update successfully");
                // toggle2()
                handleClose()
                adminProfile()
            }
        },
            (error) => {
                if (error.response && error.response.status === 400) {
                    toast(error.response.data.message);
                }
            }

        )
    }

    const getpopup = (data) => {
        //updateDistriclist();
        setdata(data);
        // toggle2();
        handleShow()
    };

    //Upadte password

    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updatePASS();
    };

    const updatePASS = () => {
        var token = sessionStorage.getItem("token");
        var _id = sessionStorage.getItem("AdminId");
        const Params = {
            "currentPassword": form.currentPassword,
            "newPlainPassword": form.newPlainPassword,
            "confirmPassword": form.confirmPassword,
        };
        console.log(Params)
        axios.post(URL.changepassword, Params,
            {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(
                (res) => {
                    if (res.status === 200) {
                        toast("Password changed successfully");
                        clearInput();
                    }
                },
                (error) => {
                    if (error.response && error.response.status === 400) {
                        toast(error.response.data.message);
                    }
                }
            );
    };




    return (
        <div>
            {" "}
            <Box sx={{ display: "flex" }} style={{ background: "#eee" }} className="cardmrg">
                <Drawer></Drawer>
                <CssBaseline />
                <Box component="main" sx={{ flexGrow: 2, p: 4 }}>
                    {/* <DrawerHeader /> */}
                    <Row>
                        <Col lg={12} className="cpad">
                            {/* <h1>profile</h1> */}
                            <>
                                <div className='card'>
                                    <div style={{ padding: "10px" }} className="cpad" >
                                        <div className='row cpad' style={{ padding: "0px" }} >
                                            <div className='col'>
                                                <h4 className="mt-4">Settings</h4>
                                            </div>
                                            <div className='col'>
                                                <NavLink to="/admin" ><button className='btn btn mt-3' style={{ float: "right" }}><HomeIcon style={{ marginBottom: "-5px", marginRight: "8px" }} />Dashboard</button></NavLink>
                                            </div>
                                        </div><hr />
                                        {/* <div className='container mt-5'>
                                            <div className='card ' style={{ backgroundImage: `url(${backgroundimg})`, height: "150px" }}>

                                                <img src={logo}  className='profilelogo'/>
                                            </div>
                                        </div> */}
                                        <div className="logo2pos">
                                            <img src={logo} className='profilelogo ' />
                                        </div>

                                        <div className='row ' style={{ padding: "20px" }}>

                                            {/* <div className='card' style={{ padding: "20px" }}>
                      <img src={logo} width="150px" />
                    </div> */}

                                            <div className='col-lg-6 ' style={{ padding: "10px" }}>
                                                <div className='card' style={{ border: "none", boxShadow: "0 6px 12px #8c98a4", padding: "20px" }}>
                                                    <p style={{ fontSize: "20px" }}><ErrorIcon style={{ marginBottom: "-4px" }} /> Basic information</p><br /><hr />

                                                    <div className='row '>
                                                        <div className='col-4 gridstyle'>
                                                            <p style={{ padding: "5px", marginTop: "15px"  }}>Full Name :</p>
                                                            <p style={{ padding: "10px", marginTop: "15px" }}>Email :</p>
                                                            <p style={{ padding: "10px", marginTop: "15px" }}>Phone :</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            {/* <div className='input-group profilefield mt-4'> */}
                                                                <p className="ptext">{profiles.username}</p><hr />
                                                            {/* </div> */}
                                                            {/* <div className='input-group profilefield mt-4'> */}
                                                                <p className="ptext">{profiles.email}</p><hr />
                                                            {/* </div> */}
                                                            {/* <div className='input-group profilefield mt-4'> */}
                                                                <p className="ptext">{profiles.contactNumber}</p><hr />
                                                            {/* </div> */}
                                                            {/* <input type="text" value="547658768" className='form-control mt-4' />
                                                            <input type="text" value="admin@agroteck.in" className='form-control mt-4' /> */}
                                                            <button className='btn btn mt-3'  onClick={() => { getpopup(profiles) }} style={{ float: "right",border:"2px solid #8c7967",color:"#8c7967" }}>Save & Change</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6' style={{ padding: "10px" }}>
                                                <div className='card' style={{ border: "none", boxShadow: "0 6px 12px #8c98a4", padding: "20px" }}>
                                                    <p style={{ fontSize: "20px" }}>Change your password</p><br /><hr />

                                                    <div className='row'>
                                                        <div className='col-5 gridstyle'>
                                                            <p style={{ padding: "23px 0px 0px 0px" }} className="cpad">Current password :</p>
                                                            <p style={{ padding: "25px 0px 0px 0px" }} className="cpad"> New password :</p>
                                                            <p style={{ padding: "25px 0px 0px 0px" }} className="cpad"> Confirm password :</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" name="currentPassword" ref={textInput} value={form.currentPassword} placeholder='Enter Current password' onChange={(e) => { handleChange(e); }} className='form-control mt-3' />
                                                            <input type="text" name="newPlainPassword" ref={textInput1} value={form.newPlainPassword} placeholder='Enter new password' onChange={(e) => { handleChange(e); }} className='form-control mt-4' />
                                                            <input type="text" name="confirmPassword" ref={textInput2} value={form.confirmPassword} placeholder='Conform your new password' onChange={(e) => { handleChange(e); }} className='form-control mt-4' />
                                                            <button className='btn btn-primary mt-3' style={{ float: "right",background:"#8c7967" }} onClick={handleSubmit}>Save & Change</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>



                                    </div>
                                </div>

                                {/* <Modal centered isOpen={modal2} onHide={toggle2}>
                                    <ModalHeader >Edit Profile </ModalHeader >
                                    <ModalBody>
                                        <form >
                                            <Label>Admin Name :</Label>
                                            <Input type="text" placeholdr="Enter Name" className="form-control" name="username" value={data.username} onChange={(e) => { ehandlechange(e); }} required />
                                            <Label>Emial Id :</Label>
                                            <Input type="text" placeholdr="Enter Name" className="form-control" name="email" value={data.email} onChange={(e) => { ehandlechange(e); }} required />
                                            <Label>Contact :</Label>
                                            <Input type="text" placeholdr="Enter Name" className="form-control" name="contactNumber" value={data.contactNumber} onChange={(e) => { ehandlechange(e); }} required />
                                            <div style={{ float: "right" }}>
                                                <Button color="danger" onClick={toggle2} >
                                                    Cancel
                                                </Button>
                                                <Button color="" type="button" style={{ background: "green" }} onClick={editProfile}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </form>
                                    </ModalBody>
                                </Modal> */}
                                <Modal show={show} onHide={handleClose} className="modalpad">
                                    <Modal.Header >
                                        <Modal.Title>Edit Profile</Modal.Title><a onClick={handleClose}> <i class="fa fa-times cros" aria-hidden="true"></i></a>
                                    </Modal.Header>
                                    <Modal.Body className="modalbod">
                                    <form >
                                            <Label>Admin Name :</Label>
                                            <Input type="text" placeholdr="Enter Name" className="form-control" name="username" value={data.username} onChange={(e) => { ehandlechange(e); }} required />
                                            <Label className="mt-2">Emial Id :</Label>
                                            <Input type="text" placeholdr="Enter Name" className="form-control" name="email" value={data.email} onChange={(e) => { ehandlechange(e); }} required />
                                            <Label className="mt-2">Contact :</Label>
                                            <Input type="text" placeholdr="Enter Name" className="form-control" name="contactNumber" value={data.contactNumber} onChange={(e) => { ehandlechange(e); }} required />
                                            <div className="mt-3" style={{ float: "right" }}>
                                                <Button  className="mr-2 bg-danger" onClick={handleClose} >
                                                    Cancel
                                                </Button>
                                                <Button className="mr-2 bg-success" type="button" style={{ background: "green" }} onClick={editProfile}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </form>
                                        </Modal.Body>
                                    
                                </Modal>

                            </>
                        </Col>
                    </Row>
                    {/* <Paper elevation={3}></Paper> */}
                </Box>
            </Box>
            <ToastContainer />
        </div>
    );
}

export default Profile;
