import React ,{useState,useEffect} from 'react'
import logo from "../assets/images/logo_IGI.png";
// import useGlobalContext from '../hooks/useGlobalContext';

function Header() {
    // const stickyMenu = useGlobalContext();

    const [sticky, setSticky] = useState("");
    const [visible, setvisible] = useState("");


    useEffect(() => {
        // handleShow();

        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };


    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 250 ? "sticky" : "";
        const visible = scrollTop >= 250 ? "-800px" : "";
        setSticky(stickyClass);
        console.log(stickyClass);
        setvisible(visible);
    };

    const classes = `navbar navbar-expand-lg navbar-light head fixed-top ${sticky}`;
    return (
        <div>
            <nav class={classes}>


                <a class="navbar-brand ">
                    <img src={logo} alt="logo" className='logo1' />
                </a>


                {/* <div style={{float:"right"}}>
                <i class="fa fa-bars sidemenu arowhide menuactivity" aria-hidden="true" href="#"></i>
                </div> */}
                <div class="navbar-collapse collapse" id="navbarsExampleDefault">

                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                ABOUT US
                            </a>
                            <div class="dropdown-menu collapse" aria-labelledby="navbarDropdown" >
                                <ul>
                                    <li class="dropdown-item"><a href="#" >About
                                        IGI</a></li>
                                    <li class="dropdown-item"><a href="#">Press Room</a>
                                    </li>
                                    <li class="dropdown-item"><a href="#" >Timeline</a></li>
                                    <li class="dropdown-item"><a href="#" >Affiliations</a></li>
                                    <li class="dropdown-item"><a href="#" >Importance of Certification</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                REPORTS
                            </a>
                            <div class="dropdown-menu collapse" aria-labelledby="navbarDropdown" >
                                <ul>
                                    <li class="dropdown-item"><a href="#" target="">Our
                                        Expertise</a></li>
                                    <li class="dropdown-item dropdown dropright"><a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">How to Read a Report</a>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <ul>
                                                <li class="dropdown-item"><a href="#" target="">Natural Diamond Report</a>
                                                </li><li class="dropdown-item"><a href="#" target="">LG
                                                    Report</a>
                                                </li></ul>
                                        </div>
                                    </li>
                                    <li class="dropdown-item dropdown dropright"><a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Diamond Reports</a>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <ul>
                                                <li class="dropdown-item"><a href="#" target="">Diamond</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Laboratory Grown</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Hearts &amp; Arrows</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Colored Diamonds</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Grading Process</a>
                                                </li></ul>
                                        </div>
                                    </li>
                                    <li class="dropdown-item"><a href="#" target="">Jewelry Report</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Colored
                                        Stone Report</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Screening Services</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Sorting Services</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Security
                                        Seal</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Laserscribe</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Verify Your Report</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                EDUCATION
                            </a>
                            <div class="dropdown-menu collapse" aria-labelledby="navbarDropdown" >
                                <ul>
                                    <li class="dropdown-item"><a href="#" target="">Gemological Education</a></li>
                                    <li class="dropdown-item"><a href="#" target=""></a></li>
                                    <li class="dropdown-item dropdown dropright"><a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Courses</a>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <ul>
                                                <li class="dropdown-item"><a href="#" target="">Polished Diamond Course</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Rough Diamond Course</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Colored
                                                    Stones Course</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Pearl Course</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Jewelry
                                                    Design Course</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Jewelry Cad Course</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Prof. Jewelry Design -
                                                    Matrix Course </a>
                                                </li><li class="dropdown-item"><a href="#" target="">CorelDraw Design Course</a>
                                                </li><li class="dropdown-item"><a href="#" target="">JewelPad
                                                    Design Course</a>
                                                </li><li class="dropdown-item"><a href="#" target="">Retail
                                                    Support Program</a>
                                                </li></ul>
                                        </div>
                                    </li>
                                    <li class="dropdown-item"><a href="#" target="">Course Schedules</a></li>
                                    <li class="dropdown-item"><a href="#" target="">E-Learning</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Tuition
                                        Fees</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Enrollment Form</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Enquiry
                                        Form</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Gemstone Articles</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Retail
                                        Support</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Learn with Videos</a></li>
                                    <li class="dropdown-item"><a href="#" target="">Verify a Diploma</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link " href="#">
                                Gemblog
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link " href="#">
                                SHOWS
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link " href="#">
                                Consumer Services
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                CONTACT
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <ul>
                                    <li class="dropdown-item"><a href="#" target="">Contact</a>
                                    </li>
                                    <li class="dropdown-item"><a href="#" target="">Enquiry
                                        Form</a></li>
                                </ul>
                            </div>
                        </li>
                        {/* <!-- <li class="nav-item dropdown dropdown_men d-none">
<a class="nav-link" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true"
    aria-expanded="false"><img src="https://www.igi.org/assets/images/language-1.svg" alt=""
        width="20"/></a>
</li> --> */}

                        <li class="nav-item dropdown dropdown_men">
                            <a class="nav-link dropdown-toggle p-0" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select Language">
                                <img src="https://igi.org.in/icon/en-lang.png" alt="" width="40" />
                            </a>

                            <div class="dropdown-menu p-0 drop-lang collapse" aria-labelledby="navbarDropdown" >

                                <select id="lang_switcher" name="lang_switcher" class="lang-dropdown" size="6">
                                    <option value="en" selected="selected">English</option>
                                    <option value="ch">简体中文</option>
                                    <option value="tc">繁中文</option>
                                    <option value="it">Italian</option>
                                    <option value="ar">Arabic</option>
                                </select>
                            </div>
                        </li>

                        <li class="nav-item dropdown dropdown_men">

                            <a class="nav-link ghost_btn" href="#" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Verify your Report</a>

                            <div class="dropdown-menu dropdown-menu-right p-0 menu-vr-form" aria-labelledby="navbarDropdown">
                                <form class="form-inline" action="/getreports" method="post">
                                    <div class="form-group mr-sm-3">
                                        <input type="text" class="form-control" placeholder="Enter Report No." id="verifyReport_h" name="verifyReport_h" required="" />
                                    </div>
                                    <button type="submit" class="btn btn-info"><i class="fa fa-search" aria-hidden="true"></i></button>
                                </form>
                            </div>
                        </li>

                        <li class="nav-item dropdown menu-qr">
                            <a class="nav-link" href="#">
                                <img src="https://www.igi.org/assets/images/qr-code-b.svg" alt="QR Code" class="qr-img" width="22" />
                            </a>
                        </li>

                    </ul>

                </div>


            </nav>
        </div>
    )
}

export default Header