import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { Link, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Row,
  Col,
  Card,
  CardBody,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // Modal,
  Button,
  Input,
  Label,
  Form,
  CardHeader,
} from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Table, UncontrolledTooltip } from "reactstrap";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import add1 from "../assets/img/new/add1.jpg";
// import img2 from "../assets/img/new/img2.jpg";
// import img3 from "../assets/img/new/img3.jpg";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from "react-paginate";
import Checkbox from '@mui/material/Checkbox';
import download from "../assets/images/download.png";
import qrcode from "../assets/images/qrcode.png";
import { height } from "@mui/system";
import { URL } from "../Apiurl";
import { useNavigate } from 'react-router'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { saveAs } from 'file-saver'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Adddetails = () => {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [carddetails, setcarddetails] = useState([])



  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const [details, setdetails] = useState([]);
  const [file, setFile] = useState()
  const [data1, setdata1] = useState([])
  const [data, setdata] = useState([])
  const [data2, setdata2] = useState([])
  const [search, setsearch] = useState("");
  const [state, setstate] = useState([])
  const [district, setdistrict] = useState([])
  const [cities, setcity] = useState([])
  const [village, setvillages] = useState([])
  const [form, setform] = useState([])
  const [image, setimage] = useState('');

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = details.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(details.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };



  // add
  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  }

  // edit
  const ehandlechange = (e) => {
    let myUser = { ...data };
    myUser[e.target.name] = e.target.value;
    setdata(myUser);
  }

 


  useEffect(() => {
    getAllAddDetails();
    // districts()
    // getAllStates()
    // city()
    // villages()
    // getAllDepartment();
  }, []);

  const getAllAddDetails = () => {
    var token = sessionStorage.getItem("token")
    axios.get(URL.getcards, {
      headers: { Authorization: `Bearer ${token}` }
    }).then((res) => {
      console.log(res.data)
      setdetails(res.data.summariesResult)
    })
  }

  const addCards = () => {
    var token = sessionStorage.getItem("token");
    var formData = new FormData();
    formData.append("summaryNumber", form.summaryNumber);
    formData.append('summaryImg',  image[0]);
    formData.append("shapeCut", form.shapeCut);
    formData.append("totalEstWt", form.totalEstWt);
    formData.append("color", form.color);
    formData.append("clarity", form.clarity);
    formData.append("comment", form.comment);
    formData.append("description", form.description);

    axios.post(URL.addcards, formData, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      // if (res.status === 200) {
      console.log("success")
      toast("Added Successfully ");
      getAllAddDetails()
      handleClose();
      // toggle();
      // }
    })

  }

  const cardsSubmit = (e) => {
    e.preventDefault();
    addCards();
  };

  
  const cardsEdit = (e) => {
    e.preventDefault();
    editCards();
  };


  const editCards = () => {
    var token = sessionStorage.getItem("token");
    var id = data._id;
    var formData = new FormData();
    formData.append("summaryNumber", data.summaryNumber);
    formData.append('summaryImg',  image[0]);
    formData.append("shapeCut", data.shapeCut);
    formData.append("totalEstWt", data.totalEstWt);
    formData.append("color", data.color);
    formData.append("clarity", data.clarity);
    formData.append("comment", data.comment);
    formData.append("description", data.description);
    console.log(data.description)
    axios.put(URL.editcards + "/" + id, formData, {
      headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' },
    }).then((res) => {
      if (res.status === 200) {
        console.log("upaded succes")
        toast("Updated successfully ");
        // toggle2()
        getAllAddDetails()
        handleClose1()
      }
    },
      (error) => {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message);
        }
      }

    )
  }

  const getpopup = (data) => {
    setdata(data);
    // toggle2();
    handleShow1()
  };
  const getpopup1 = (val) => {
    handleShow2()
    setdata2(val);
    console.log("value",val._id)
    getAllAddDetails(val)
    // toggle2();
  };

  const cardDetails = (summariesResult) => {
    var token = sessionStorage.getItem("token");
    var paras = {
      _id: summariesResult._id
    }
    console.log(paras)
    axios.post(URL.getonecards, paras, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      console.log(res.data)
      if (res.status === 200) {
        navigate('/view-details')
        setcarddetails(res.data)
      }
    })

  }
  const handleView = (d) => {
    setcarddetails(d);
    cardDetails(d)
    // console.log('UpdataData--',d);
    sessionStorage.setItem('viewdetails', JSON.stringify(d));
    navigate('/view-details');
  }




  const deleteCards = (summariesResult) => {
    var token = sessionStorage.getItem("token");
    axios.delete(URL.deletecards + "/" + summariesResult, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.status === 200) {
        getAllAddDetails()
        toast("Deleted successfully");
      }
    })

  }

  const manageDelete = (summariesResult) => {
    const confirmBox = window.confirm("Do you want to Delete?");
    if (confirmBox === true) {
      deleteCards(summariesResult);
    }
  };

  // function FilehandleChange(event) {
  //   setFile(event.target.files[0])
  // }
  
  const handleImageUpload = (e) => {
    const file=e.target.files;
    console.log(file);
    var ext =  file[0].name.split('.').pop();
   var type= ext;
   console.log(type);
    if( type=='jpg'||type =='jpeg'|| type =='png')
    {
      setimage(e.target.files);
      console.log(e.target.files);
      console.log("e.target.files");
      // FilehandleChange()
    }
    else
    {
      e.target.value = null;
      toast("file format not supported.Pls choose jpg/jpeg/png");
      
    }
  }


  const downloadImage = () => {
    saveAs("https://api.igi-org.in/" + data2.qrcode) // Put your image url here.
  }

  return (
    <div >
      {" "}
      <Box sx={{ display: "flex" }} style={{ background: "#ebeaea" }} className="cardmrg" >
        <Drawer></Drawer>
        <CssBaseline />
        <Box component="main" style={{ background: "#ebeaea" }} sx={{ flexGrow: 2, p: 4 }}>
          {/* <DrawerHeader /> */}
          <Row>
            <Col lg={12}>
              <Card className="cardstyle">
                <CardHeader className="cardheader">
                  <div className="row">
                    <div className="col">
                      <h4 className="cardh4"><b>Cards</b></h4>
                    </div>
                    <div className="col ">
                      <Button
                        type="button"
                        className="btn pdf-view-btn addbtn"
                        // style={{ float: "right", marginTop: "34px" }}
                        onClick={handleShow}
                      >
                        <i class="fa fa-plus mr-1" aria-hidden="true"></i> Add Cards
                      </Button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody>

                  {/* <div className="row container mt-3"> */}
                  {/* <div className="col mt-4"> */}
                  <div style={{ float: "right" }}>
                    <input
                      type="search"
                      placeholder="search..."
                      className="form-control mb-3"
                      style={{ width: "100%" }}
                      onChange={(e) => { setsearch(e.target.value) }}
                    />
                  </div>

                  {/* </div> */}
                  {/* <div className="col ">
                      <Button
                        type="button"
                        className="btn pdf-view-btn addbtn"
                        onClick={handleShow}
                      >
                        <i class="fa fa-plus mr-1" aria-hidden="true"></i> Add Data
                      </Button>
                    </div> */}
                  {/* </div> */}
                  <div className="table-responsive mt-3">
                    <Table
                      bordered
                      className="table-centered datatable dt-responsive nowrap "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead
                        className="thead-light"
                        style={{ background: "#1562a0", color: "white" }}
                      >
                        <tr>
                          <th>Sl.no</th>
                          <th>Summary No</th>
                          {/* <th>Description</th> */}
                          <th>Shape/cut</th>
                          <th>Total Est. Wt.</th>
                          <th>Color</th>
                          <th>Clarity</th>
                          {/* <th>Comments</th> */}
                          <th>Image</th>
                          {/* <th>Qr Code</th> */}
                          {/* <th>Wallet Balance</th>
                          <th>Joining Date</th> */}

                          <th style={{ width: "145px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists
                          .filter((summariesResult) => { /////////to filter table data with input values ////// 
                            if (search === ! null) {
                              return summariesResult
                            }
                            else if (summariesResult.summaryNumber.toUpperCase().includes(search.toUpperCase())) {
                              return summariesResult
                            }
                          })
                          .map((summariesResult, key) => (
                            <tr key={key}>
                              <td>{(pageNumber - 1) * 10 + key + 11}</td>
                              <td>{summariesResult.summaryNumber}</td>
                              <td>{summariesResult.shapeCut}</td>

                              <td>{summariesResult.totalEstWt}</td>
                              <td>{summariesResult.color}</td>
                              <td>{summariesResult.clarity}</td>
                              <td><img src={"https://api.igi-org.in/" + summariesResult.softcopy} style={{ width: "100px", height: "90px" }} /></td>

                              {/* <td onClick={() => getpopup1(summariesResult)}> <img src={"https://api.igi-org.in/" + summariesResult.qrcode} style={{ width: "100px", height: "90px" }} /></td> */}
                              <td>
                                {/* <Link
                                  to="#"
                                  className="mr-3 text-success"
                                  id={"edit" + key}
                                  onClick={() => { getpopup(summariesResult) }}
                                >
                                  <ModeEditIcon />
                                </Link>
                                <UncontrolledTooltip
                                  target={"edit" + key}
                                  placement="top"
                                >
                                  Edit
                                </UncontrolledTooltip>
                                <Link
                                  to="#"
                                  className="mr-3 text-primary"
                                  id={"view" + key}
                                  onClick={() => { handleView(summariesResult) }}
                                >
                                  <RemoveRedEyeIcon />
                                </Link>
                                <UncontrolledTooltip
                                  target={"view" + key}
                                  placement="top"
                                >
                                  view
                                </UncontrolledTooltip>
                                <Link
                                  to="#"
                                  className="text-danger"
                                  id={"delete" + key}
                                  onClick={() => { manageDelete(summariesResult._id) }}
                                >
                                  <DeleteOutlineIcon></DeleteOutlineIcon>
                                </Link>
                                <UncontrolledTooltip
                                  target={"delete" + key}
                                  placement="top"
                                >
                                  Delete
                                </UncontrolledTooltip> */}

                            <div className="mt-4">
                            <span className="adminbtnbad"><a className="btn adminbtns" onClick={() => { getpopup(summariesResult) }}> <ModeEditIcon className="text-success" /></a></span>
                            <span className="adminbtnbad"><a className="btn adminbtns2"  onClick={() => { handleView(summariesResult) }}> <RemoveRedEyeIcon className=" text-primary" /></a></span>
                            <span className="adminbtnbad"><a className="btn adminbtns3" onClick={() => { manageDelete(summariesResult._id) }}> <DeleteOutlineIcon className="text-danger"/></a></span>
                            </div>
                              </td>

                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {/* 
                    <div style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                      />
                    </div> */}
                        <div className="mt-3" style={{ float: "right" }}>
                {/* <Stack spacing={2}> */}
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    total={lists.length}
                  />
                {/* </Stack> */}
              </div>




                    <Modal size="lg" show={show} onHide={handleClose} className="modalpad" >
                      <Modal.Header  >
                        <Modal.Title>Add Cards Data </Modal.Title><a onClick={handleClose}> <i class="fa fa-times cros" aria-hidden="true"></i></a>

                      </Modal.Header>
                      <Modal.Body className="modalbod">
                        <form onSubmit={cardsSubmit}>
                          <div className="row">
                            <div className="col">
                              <Label>Summary No :</Label>
                              <Input type="text"  pattern="^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Summary No"  maxlength="30" className="form-control" name="summaryNumber" onChange={(e) => { handlechange(e); }} required />

                              <Label className="mt-2">Shape/cut :</Label>
                              <Input type="text"  pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Shape/cut" maxlength="30" className="form-control" name="shapeCut" onChange={(e) => { handlechange(e); }} required />

                              <Label className="mt-2">Total Est. Wt. :</Label>
                              <Input type="text"  pattern="^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Total Est. Wt." maxlength="30" className="form-control" name="totalEstWt" onChange={(e) => { handlechange(e); }} required />
                            </div>
                            <div className="col">
                              <Label>Color :</Label>
                              <Input type="text"  pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" maxlength="30" placeholder="Color" className="form-control" name="color" onChange={(e) => { handlechange(e); }} required />
                              <Label className="mt-2">Clarity :</Label>
                              <Input type="text"  pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Clarity" maxlength="30" className="form-control" name="clarity" onChange={(e) => { handlechange(e); }} required />
                              <Label className="mt-2">Comments :</Label>
                              <Input type="text" placeholder="Comments" maxlength="100" className="form-control" name="comment" onChange={(e) => { handlechange(e); }} required />


                            </div>
                          </div>
                          <Label className="mt-2">Image :</Label>
                          <Input type="file" formcontrolname="uploadFile" className="form-control" 
                          name="summaryImg"
                          onChange={(e) => { handleImageUpload(e)}}
                          //  onChange={(e) => handleImageUpload(e); {FilehandleChange}}

                            required />

                          <Label className="mt-2">Description :</Label>
                          <textarea type="text" placeholder="Enter Description" maxlength="137"  className="form-control" name="description" onChange={(e) => { handlechange(e); }} required />
                          <div style={{ float: "right" }} className="mt-3">
                            <Button className="mr-3 bg-danger" onClick={handleClose}>
                              Cancel
                            </Button>
                            <Button color="success" type="submit">
                              Submit
                            </Button>
                          </div>

                        </form>
                      </Modal.Body>
                    </Modal>

                    <Modal size="lg" show={show1} onHide={handleClose1} className="modalpad" >
                      <Modal.Header  >
                        <Modal.Title>Edit Cards Data </Modal.Title><a onClick={handleClose1}> <i class="fa fa-times cros" aria-hidden="true"></i></a>

                      </Modal.Header>
                      <Modal.Body className="modalbod">
                        <form onSubmit={cardsEdit} >
                          <div className="row">
                            <div className="col">
                              <Label>Summary No :</Label>
                              <Input type="text" pattern="^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Summary No" maxlength="30" value={data.summaryNumber} className="form-control" name="summaryNumber" onChange={(e) => { ehandlechange(e); }} required />

                              <Label className="mt-2">Shape/cut :</Label>
                              <Input type="text" pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Shape/cut" maxlength="30" value={data.shapeCut} className="form-control" name="shapeCut" onChange={(e) => { ehandlechange(e); }} required />

                              <Label className="mt-2">Total Est. Wt. :</Label>
                              <Input type="text" pattern="^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Total Est. Wt." maxlength="30" value={data.totalEstWt} className="form-control" name="totalEstWt" onChange={(e) => { ehandlechange(e); }} required />
                            </div>
                            <div className="col">
                              <Label>Color :</Label>
                              <Input type="text" pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Color" maxlength="30" value={data.color} className="form-control" name="color" onChange={(e) => { ehandlechange(e); }} required />
                              <Label className="mt-2">Clarity :</Label>
                              <Input type="text" pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" placeholder="Clarity" maxlength="30" value={data.clarity} className="form-control" name="clarity" onChange={(e) => { ehandlechange(e); }} required />
                              <Label className="mt-2">Comments :</Label>
                              <Input type="text" placeholder="Comments" maxlength="100" value={data.comment} className="form-control" name="comment" onChange={(e) => { ehandlechange(e); }} required />


                            </div>
                          </div>
                          <Label className="mt-2">Image :</Label>
                          <Input type="file" className="form-control" pattern="/.(jpg|jpeg|png|gif)$/" name="summaryImg" 
                          // onChange={FilehandleChange}
                          onChange={(e) => { handleImageUpload(e)}}
                           required />

                          <Label className="mt-2">Description :</Label>
                          <textarea type="text" placeholder="Enter Description" maxlength="137" value={data.description} className="form-control" name="description" onChange={(e) => { ehandlechange(e); }} required />
                          <div style={{ float: "right" }} className="mt-3">
                            <Button className="mr-3 bg-danger" onClick={handleClose1}>
                              Cancel
                            </Button>
                            <Button color="success" type="submit">
                              Submit
                            </Button>
                          </div>

                        </form>
                      </Modal.Body>
                    </Modal>

                    <Modal show={show2} onHide={handleClose2} className="modalpad">
                      <Modal.Header>
                        <Modal.Title>Qr Code</Modal.Title> <a onClick={handleClose2}> <i class="fa fa-times cros" aria-hidden="true"></i></a>

                      </Modal.Header>
                      <Modal.Body className="modalbod" >
                        <img src={"https://api.igi-org.in/" + data2.qrcode} style={{ width: "100%", height: "400px" }} alt='image' className="imgstyle" />
                        <button  onClick={downloadImage} target="_blank" className="btn btn form-control mt-4 bg-dark text-white"><i class="fa fa-arrow-circle-down mr-1" aria-hidden="true"></i> Download</button>
                      </Modal.Body>
                    </Modal>


                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Paper elevation={3}></Paper> */}
        </Box>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default Adddetails;
