import React, { useState } from 'react'
import "../assets/css/form.css"
import { Link, NavLink } from 'react-router-dom';
// import logo from "../assets/img/logo/logo.png";
import logo from "../assets/images/logo_IGI.png";
import { useNavigate } from 'react-router'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { URL} from "../Apiurl";



const Login = () => {
  
    const [admin,setadmin]=useState({email:"",password:""});
    let navigate = useNavigate();
    const handleChange = (e) => {
        let newadmin = { ...admin };
        newadmin[e.target.name] = e.target.value;
        setadmin(newadmin);
      };

      const addAdmin=()=>{
        var paras = {
            email: admin.email,
            password: admin.password,
          };

          axios.post(URL.loginUrl,paras).then((res)=>{
            if(res.status===200){
                console.log(res.data)
                sessionStorage.setItem("AdminId",res.data.user._id)
                sessionStorage.setItem("email",res.data.user.email)
                sessionStorage.setItem("role",res.data.user.role)
                sessionStorage.setItem("token",res.data.token)
                window.location.href="/admin";
                // navigate('/admin')
            }
          },
          (error) => {
            if (error.response && error.response.status === 400) {
              toast(error.response.data.message);
            }
          }
          );
      }

    return (
        <>
            <div class="section1">
                <div class="container1" id="container1" style={{width: "400px"}}>
                    <div class="container">
                        <form class=" mt-4">
                            <a href="#"><NavLink to="/"><img src={logo} alt="logo" className='limg' /></NavLink></a>
                            <h2 className='mt-2'>Sign In</h2>
                            <input type="email" name="email"  placeholder="Email" value={admin.email} onChange={(e) => {handleChange(e);}} required/>
                            <input  id="password" name="password" type="password" placeholder="Password" value={admin.password} onChange={(e) => {handleChange(e);}} required/>
                            <a className='mt-4' ><NavLink to="/Forgotepsw">Forgot your password?</NavLink></a>
                           <button type="button" style={{marginTop:"-8px"}}  onClick={addAdmin }>Sign In</button>
                        </form>
                    </div>
                 
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Login