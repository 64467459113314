import React, { useState, useEffect} from 'react'
import logo from './logo.svg';
import './App.css';
// import "./Css/common.css";
// import "./Css/zzz.css";
import Main2 from "./Components/Main2";
import Main from "./Components/Main";
import { BrowserRouter, Routes, Route ,useParams} from "react-router-dom";
import Dashboard from "./Admin/Dashboard";
import Adddetails from "./Admin/Adddetails";
import Login from "./Admin/Login";
import Profile from "./Admin/Profile";
import Forgotepsw from "./Admin/Forgotepsw";
// import ReactLoading from 'react-loading';
import LoadingScreen from "react-loading-screen";
import whitelogo from "../src/assets/images/loading.png";
import { textAlign } from '@mui/system';
import Details from "./Admin/Details";
import Admins from "../src/Admin/Admins";
import Cardprint from "./Admin/Cardprint";
import Print from "./Admin/Print";
import Setting from "./Admin/Setting";
import Error from "./Components/Error";
// import { useParams } from "react-router-dom";


function App() {
  
  // const {id}=502
  // /const params = useParams();
  const [memes, setMemes] = useState({});
  const [isLoding, setloading] = useState(true);
  const [spinner, setSpinner] = useState(true);


  useEffect(() => {
    setSpinner(true);
    // fetch("https://api.imgflip.com/get_memes")
    //   .then((res) => res.json())
    //   .then((data) => setMemes(data));

    setTimeout(() => {
      setSpinner(false);
    }, 2000);
  }, []);

  return (
    < >
     {/* <LoadingScreen
        loading={true}
        bgColor="#565656cc"
        // bgColor="none"
        spinnerColor="#9ee5f8"
        textColor="#fff"
        logoSrc={whitelogo}
        text="Loading"
        width="100%"
        position="fixed"
      >
        {" "}
      </LoadingScreen> */}
       {/* {isLoding ? <div className="entry">
        <img src={whitelogo}  className="img-fluid mx-auto d-block footer_logo imgmar bounce"  /> 
        <h1 className='text-white text-center'>Loading...</h1>
       </div> : <Main memes={memes} />} */}
       {/* {spinner && (
        <div className='loadingstyle'>
          <img src={whitelogo}  className="img-fluid mx-auto d-block footer_logo imgmar bounce"  />
      
        <h4 className='text-white'>Loading...</h4>
        </div>
        )} */}
      <BrowserRouter>
        <Routes>
          <Route path="/reports/verify-your-report/:id" element={<Main2 />} />
          {/* <Route path="/reports/verify-your-report/" element={<Main />} /> */}
          <Route path="/Login" element={<Login />} />
          <Route path="/Maintenece" element={<Error />} />
          <Route path="/Forgotepsw" element={<Forgotepsw />} />
          {(sessionStorage.getItem('AdminId')!='' && sessionStorage.getItem('AdminId'))? 
          <>
          <Route path="/Cardprint" element={<Cardprint />} />
          <Route path="/Print" element={<Print />} />
          <Route path="/Setting" element={<Setting />} />
          <Route path="/admin" element={<Dashboard />} key="reload" />
          <Route path="/Adddetails" element={<Adddetails />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/view-details" element={<Details />} />
          <Route path="/Admins" element={<Admins />} />
          </>
           :''} 
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
