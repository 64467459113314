import React,{useEffect,useState} from 'react'
import whitelogo from "../assets/images/whitelogo.png";
// import whitelogo from "../assets/images/loading.png";
import axios from "axios";
import { URL } from "../Apiurl";

function Footer() {
    const [visible, setvisible] = useState("");
    const [lefts, setlefts] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [form, setform] = useState([])
    const [getdata, setgetdata] = useState([]);


    const handlechange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
      }
    
    useEffect(() => {
        // handleShow();

        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };


    }, []);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const visible = scrollTop >= 250 ? "" : "-800px";
        // const lefts = scrollTop >= 250 ? "" : "verify-form slideLeft";
        setvisible(visible);
        // setlefts(lefts);
    };


    



    const getAlldetails=()=>{
        var paras = {
            "sumid": form.sumid
            // password: admin.password,
          };

          axios.post(URL.getdetails,paras).then((res)=>{
            if(res.status===200){
                console.log(res.data.summaryResult)
                setgetdata(res.data.summaryResult)
                // sessionStorage.setItem("AdminId",res.data.user._id)
                // sessionStorage.setItem("email",res.data.user.email)
                // sessionStorage.setItem("role",res.data.user.role)
                // sessionStorage.setItem("token",res.data.token)
                // window.location.href="/admin";
                // navigate('/admin')
            }
          },
        //   (error) => {
        //     if (error.response && error.response.status === 400) {
        //       toast(error.response.data.message);
        //     }
        //   }
          );
      }


    const dataSubmit =()=>{
        setSpinner(true);
        getAlldetails()
       
        // fetch("https://api.imgflip.com/get_memes")
        //   .then((res) => res.json())
        //   .then((data) => setMemes(data));

        setTimeout(() => {
            setSpinner(false);
        }, 5000);
    }

  return (
    <>
         {spinner && (
        <div className='loadingstyle mobload span'>
            <img src={whitelogo} className="img-fluid mx-auto d-block footer_logo imgmar bounce" />

            <h5 className='text-white' style={{ marginTop: "-25px" ,fontSize:"20px"}}>Loading <span 
            data-text=" ..." style={{letterSpacing: "2px"}}> </span></h5>
        </div>
    )}
        {/* <footer>
        <div class="container">
            <div class="row">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1 mb-30">
                    <div class="footer_logo_area">
                        <img src={whitelogo} alt="IGI - International Gemological Institute" class="img-fluid mx-auto d-block footer_logo"/>
                        <div class="footer_slogan">
                            <span className='sloganspan' style={{textAlign: "center"}}>IGI's supreme position in the gemological world is no coincidence. It is the result of
                                continuous research, support and synergy with professionals and consumers alike.</span>
                        </div>
                    </div>
                </div> */}

                {/* <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1 mb-30">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div class="footer_menu">
                                <h4 class="footer_title">ABOUT US</h4>
                                <a href="#" class="footer_link">About IGI<br></a>
                                <a href="#" class="footer_link">PressRoom<br></a>
                                <a href="#" class="footer_link">Timeline<br></a>
                                <a href="#" class="footer_link">Affiliations<br></a>
                                <a href="#" class="footer_link">Importance of Certification<br></a>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div class="footer_menu">
                                <h4 class="footer_title">REPORTS</h4>
                                <a href="#" class="footer_link">Our Expertise<br></a>
                                <a href="#" class="footer_link">How to Read a Report<br></a>
                                <a href="#" class="footer_link">Diamond Reports<br></a>
                                <a href="#" class="footer_link">Jewelry Report<br></a>
                                <a href="#" class="footer_link">Colored Stone Report<br></a>
                                <a href="#" class="footer_link">Screening Services<br></a>
                                <a href="#" class="footer_link">Sorting Services<br></a>
                                <a href="#" class="footer_link">Security Seal<br></a>
                                <a href="#" class="footer_link">Laserscribe<br></a>
                                <a href="#" class="footer_link">Verify Your Report<br></a>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div class="footer_menu">
                                <h4 class="footer_title">EDUCATION</h4>
                                <a href="#" class="footer_link">Gemological Education<br></a>
                                <a href="#" class="footer_link">Courses<br></a>
                                <a href="#" class="footer_link">Course Schedules<br></a>
                                <a href="#" class="footer_link">E-Learning<br></a>
                                <a href="#" class="footer_link">Tuition Fees<br></a>
                                <a href="#" class="footer_link">Enrollment Form<br></a>
                                <a href="#" class="footer_link">Enquiry Form<br></a>
                                <a href="#" class="footer_link">Gemstone Articles<br></a>
                                <a href="#" class="footer_link">Retail Support<br></a>
                                <a href="#" class="footer_link">Learn with Videos<br></a>
                                <a href="#" class="footer_link">Verify a Diploma<br></a>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div class="footer_menu">
                                <h4 class="footer_title">Other</h4>
                                <!--  <a href="https://www.igi.org/reports/verify_your_report" class="footer_link">Verify your Report</a> -->
                                <a href="#" class="footer_link">Press</a>
                                <a href="#" class="footer_link">Shows</a>
                                <a href="#" class="footer_link">Consumer Services</a>
                                <a href="#" class="footer_link">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1">
                    <div class="footer_copy">
                        <div class="row">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div>
                                    <p>2020 © IGI Ltd. All rights reserved</p>
                                    <div class="footer-powered" style="float:left">
                                        <a href="#" target="_blank" class="footer_link_terms padder">Terms &amp;
                                            Conditions</a>
                                        &nbsp;|&nbsp;
                                        <a href="#" target="_blank" class="footer_link_terms padder">Privacy policy</a>
                                        &nbsp;|&nbsp;
                                        <a href="#" target="_blank" class="footer_link_terms padder">Terms of use</a>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <p align="center"><a href="http://www.beian.miit.gov.cn" target="_blank"> 沪 ICP 备
                                        19000101 号 -1</a></p>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                <div class="social_btn">
                                    Follow IGI <a href="https://www.facebook.com/InternationalGemologicalInstitute/" target="_blank">
                                        <i class="fa fa-facebook-official" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.instagram.com/igiworldwide/" target="_blank">
                                        <i class="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="footer-powered">
                                    Powered by <a href="https://www.tecogis.com" target="_blank" rel="nofollow">Tecogis</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}

            {/* </div>
        </div>
    </footer> */}


{/* new..... */}

<footer>
        <div class="container">
            <div class="row">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1 mb-30">
                    <div class="footer_logo_area">
                        <img src={whitelogo}
                            alt="IGI - International Gemological Institute"
                            class="img-fluid mx-auto d-block footer_logo" />
                        <div class="footer_slogan">
                            <span>IGI's supreme position in the gemological world is no coincidence. It is the result of
                                continuous research, support and synergy with professionals and consumers alike.</span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1 mb-30">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div class="footer_menu">
                                <h4 class="footer_title">ABOUT US</h4>
                                <a href="#" class="footer_link">About IGI<br/></a>
                                <a href="#" class="footer_link">PressRoom<br/></a>
                                <a href="#" class="footer_link">Timeline<br/></a>
                                <a href="#" class="footer_link">Affiliations<br/></a>
                                <a href="#" class="footer_link">Importance of Certification<br/></a>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div class="footer_menu">
                                <h4 class="footer_title">REPORTS</h4>
                                <a href="#" class="footer_link">Our Expertise<br/></a>
                                <a href="#" class="footer_link">How to Read a Report<br/></a>
                                <a href="#" class="footer_link">Diamond Reports<br/></a>
                                <a href="#" class="footer_link">Jewelry Report<br/></a>
                                <a href="#" class="footer_link">Colored Stone Report<br/></a>
                                <a href="#" class="footer_link">Screening Services<br/></a>
                                <a href="#" class="footer_link">Sorting Services<br/></a>
                                <a href="#" class="footer_link">Security Seal<br/></a>
                                <a href="#" class="footer_link">Laserscribe<br/></a>
                                <a href="#" class="footer_link">Verify Your Report<br/></a>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div class="footer_menu">
                                <h4 class="footer_title">EDUCATION</h4>
                                <a href="#" class="footer_link">Gemological Education<br/></a>
                                <a href="#" class="footer_link">Courses<br/></a>
                                <a href="#" class="footer_link">Course Schedules<br/></a>
                                <a href="#" class="footer_link">E-Learning<br/></a>
                                <a href="#" class="footer_link">Tuition Fees<br/></a>
                                <a href="#" class="footer_link">Enrollment Form<br/></a>
                                <a href="#" class="footer_link">Enquiry Form<br/></a>
                                <a href="#" class="footer_link">Gemstone Articles<br/></a>
                                <a href="#" class="footer_link">Retail Support<br/></a>
                                <a href="#" class="footer_link">Learn with Videos<br/></a>
                                <a href="#" class="footer_link">Verify a Diploma<br/></a>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div class="footer_menu">
                                <h4 class="footer_title">Other</h4>
                                {/* <!--  <a href="https://www.igi.org/reports/verify_your_report" class="footer_link">Verify your Report</a> --> */}
                                <a href="#" class="footer_link">Press</a>
                                <a href="#" class="footer_link">Shows</a>
                                <a href="#" class="footer_link">Consumer Services</a>
                                <a href="#" class="footer_link">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 offset-xl-1 offset-lg-1">
                    <div class="footer_copy">
                        <div class="row">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div>
                                    <p>2020 &copy; IGI Ltd. All rights reserved</p>
                                    <div class="footer-powered" style={{float:"left"}}>
                                        <a href="#" target="_blank" class="footer_link_terms padder">Terms &
                                            Conditions</a>
                                        &nbsp;|&nbsp;
                                        <a href="#" target="_blank" class="footer_link_terms padder">Privacy policy</a>
                                        &nbsp;|&nbsp;
                                        <a href="#" target="_blank" class="footer_link_terms padder">Terms of use</a>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <p align="center"><a href="#" > 沪 ICP 备
                                        19000101 号 -1</a></p>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                <div class="social_btn">
                                    Follow IGI <a href="https://www.facebook.com/InternationalGemologicalInstitute/"
                                        target="_blank">
                                        <i class="fa fa-facebook-official" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.instagram.com/igiworldwide/" target="_blank">
                                        <i class="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="footer-powered">
                                    Powered by <a href="#" target="_blank"
                                        rel="nofollow">Tecogis</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </footer>

    <div id="verifyReoprt" class="verify-form" style={{left: visible}}>
        <form id="verifyReportForm" data-name="Email Form 3" action="#" method="POST">
            <input type="text" class="verify-form-inp" placeholder="Verify your Report" name="sumid" onChange={(e) => { handlechange(e); }} required/>
            <input type="button" onClick={dataSubmit}  value="Submit" class="theme_btn_o_w" />
            {/* <button type="button" onClick={dataSubmit} class="btn btn-info"><i class="fa fa-search" aria-hidden="true"></i></button> */}
        </form>
        <span class="search-icn">
            <i id="SearchIcon" class="scroll-Report fa fa-times" aria-hidden="true"></i>
           
        </span>
    </div>




    </>
  )
}

export default Footer