import React, { useState, useEffect } from 'react'
import Header2 from "../Components/Header2";
import axios from "axios";
import { URL } from "../Apiurl";

import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HistoryIcon from "@mui/icons-material/History";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import GroupIcon from "@mui/icons-material/Group";
import Paper from "@mui/material/Paper";
import { Table, UncontrolledTooltip, Input, Label } from "reactstrap";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import axios from "axios";
import { useNavigate } from 'react-router'
import { Redirect } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
// import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';


import img1 from "../assets/images/24.png";
import img2 from "../assets/images/22.png";
import img3 from "../assets/images/25.png";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import Multiselect from 'multiselect-react-dropdown';

import { useTheme } from '@mui/material/styles';





const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));


const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

function Cardprint() {
    const [details, setdetails] = useState([]);
    // console.log(details)
    const [find, setfind] = useState([]);
    const [show, setShow] = useState(true);
     const [selectedOptions, setSelectedOptions] = useState();
    // const [DynamicValues, setDynamicValues] = useState([{ value:"1234567" }]);
    // const [indexes, setIndexes] = React.useState([]);
    // const [counter, setCounter] = React.useState(0);

    const handlechange = (e) => {
        let myUser = { ...find };
        myUser[e.target.name] = e.target.value;
        setfind(myUser);
        console.log(myUser)
    }


    // const addFriend = () => {
    //     setDynamicValues([...DynamicValues, {value:details.summaryNumber}])
    //     setIndexes(prevIndexes => [...prevIndexes, counter]);
    //     setCounter(prevCounter => prevCounter + 1);
    //   };

    //   const removeFriend = index => () => {
    //     let newFormValues = [...DynamicValues];
    //     newFormValues.splice(index, 1);
    //     setDynamicValues(newFormValues);
    //     setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    //     setCounter(prevCounter => prevCounter - 1);
    //   };

    const submitid = () => {
        // find.summaryNumber

    }


    useEffect(() => {
        getAllAddDetails();
    }, []);

    const getAllAddDetails = () => {
        var token = sessionStorage.getItem("token")
        axios.get(URL.getcards, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res.data)
            setdetails(res.data.summariesResult)
            // setPersonName(res.data.summariesResult)
        })
    }

    const Input = () => {
        // return <input placeholder="Your input here" value={details.summaryNumber} />;
        return <div class="verify-form2">
            <input type="text" class="verify-form-inp" placeholder="Verify your Report" onChange={(e) => { handlechange(e); }} name="summaryNumber"
                value={find}
            />
            <span class="search-icn" style={{ marginLeft: "75px" }}  >
                <i id="SearchIcon" class="scroll-Report fa fa-times" onClick={handleRemove} aria-hidden="true"></i>
            </span>
        </div>
    };



    const [inputList, setInputList] = useState([]);

    const onAddBtnClick = event => {
        setfind(find.concat(<Input key={find.length} />));
        // incNum()
    };

    // let incNum = () => {
    //     if (inputList<4) {
    //         setInputList(Number(inputList) + 1);
    //     }
    // };

    const handleRemove = (index) => {
        if (inputList.length !== "") {
            // const values = [...details]
            inputList.splice(index, 0)
            setInputList(inputList)
        }
    }

    const [chipData, setChipData] = React.useState([
        { key: 0, label: 'Angular' },
        { key: 1, label: 'jQuery' },
        { key: 2, label: 'Polymer' },
        { key: 3, label: 'React' },
        { key: 4, label: 'Vue.js' },
    ]);

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const ComponentToPrint = () => {
        return (
            <div>
                <Card className="cardstyle">
                    {/* <div style={{ padding: "50px" }}> */}
                    {/* <h6>Print Cards</h6><br />
                                   sfdgfdhg */}
                    <div style={{ padding: "20px" }}>
                        <div className='row' >
                            <div className='col'>
                                <img src={img1} style={{ width: "200px" }}></img>
                            </div>
                            <div className='col'>
                                <img src={img2} style={{ width: "200px" }}></img>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col'>
                                <img src={img3} style={{ width: "200px" }}></img>
                            </div>
                            <div className='col'>
                                <img src={img1} style={{ width: "200px" }}></img>
                            </div>
                        </div>
                        <div style={{ float: "right" }} className="mt-4">
                            <button className='btn btn-primary' >
                                Download
                            </button>
                        </div>
                    </div>
                    {/* </div> */}
                </Card>
            </div>
        )
    }


    const optionList = details.map((response) => ({
        value: response._id,
        label: response.summaryNumber
    }));

    function handleSelect(data) {
        setSelectedOptions(data);
    }
    return (

        <div style={{ background: "#ebeaea" }}>
            {" "}
            <Box sx={{ display: "flex" }} className="cardmrg">
                <Drawer></Drawer>
                <CssBaseline />
                <Box component="main" style={{ background: "#ebeaea" }} sx={{ flexGrow: 2, p: 4 }}>
                    {/* <DrawerHeader /> */}

                    <div style={{ padding: "10px" }}>
                        <div className='row'>
                            <div className='col-5'>
                                <Card className="cardstyle">
                                    <div style={{ padding: "50px" }}>
                                        <h6>Print Cards</h6><br />
                                        <div className='row'>
                                            <div className='col-8'>
                                                {/* <h6>Summary No:</h6> */}
                                                {/* <option value="All">All</option> */}
                                                {/* <span><select className='form-control'>
                                                    {details.map((d) => {
                                                        return <option value={d._id}>{d.summaryNumber}</option>;
                                                    })}
                                                </select></span> */}
                                                {/* <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={top100Films}
                                                    sx={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label="select" />}
                                                >
                                                    {details.map((d) => {
                                                        return <option value={d._id}>{d.summaryNumber}</option>;
                                                    })}
                                                </Autocomplete> */}

                                                {/* <Autocomplete
                                                    className="m-1"
                                                    options={details}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="SummaryNumbers"
                                                            variant="outlined"
                                                            onChange={(e) => { handlechange(e); }}
                                                            required
                                                        />
                                                    )}
                                                    getOptionLabel={(option) => option.summaryNumber}
                                                    style={{ width: 270, background: "white" }}

                                                    /> */}
                                                {/* // value={form}
                                                    // onChange={(_event, newTeam) => {
                                                    //   setSelectedTeam(newTeam);
                                                    // }} */}

                                            </div>
                                            {/* <div className='col-2' >
                                                <button
                                                    type="button"
                                                    className="btn ml-1 addbtn printcardpad"
                                                    style={{ padding: "10px" }}
                                                    onClick={addFriend}
                                                    onClick={onAddBtnClick}


                                                >
                                                    <i class="fa fa-plus mr-1" aria-hidden="true"></i>
                                                </button>
                                            </div> */}
                                            <FormControl sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    multiple
                                                    value={personName}
                                                    onChange={handleChange}
                                                    input={<OutlinedInput label="Name" />}
                                                    MenuProps={MenuProps}
                                                >
                                                    {details.map((name, i) => (
                                                        <MenuItem
                                                            key={i}
                                                            value={name.summaryNumber}
                                                            style={getStyles(name.summaryNumber, personName, theme)}
                                                        >
                                                            {name.summaryNumber}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* {inputList} */}
                                        <Paper
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap',
                                                listStyle: 'none',
                                                p: 0.5,
                                                m: 0,
                                            }}
                                            component="ul"
                                        >
                                            {personName.map((data) => {
                                                let icon;



                                                return (
                                                    <ListItem key={data.key}>
                                                        <Chip
                                                            icon={icon}
                                                            label={data}
                                                            onDelete={handleDelete(data)}
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </Paper>

                                        {/* <span><select className='form-control'>
                                                    {details.map((d) => {
                                                        return <option value={d._id}>{d.summaryNumber}</option>;
                                                    })}
                                                </select></span> */}
                                        {/* <input type="text" className="form-control mt-3" value="124345344545" style={{width:"70%",padding: "15px"}} /> */}
                                        {/* {details.map((d) => {                                           
                                           return <div value={d._id} class="verify-form2">
                                                    <input type="text" class="verify-form-inp" placeholder="Verify your Report" name="sumid" value={d.summaryNumber}  />
                                                <span class="search-icn" style={{ marginLeft: "80px" }}  >
                                                    <i id="SearchIcon" class="scroll-Report fa fa-times" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        })} */}
                                        {/* <div id="verifyReoprt" class="verify-form2">
                                                <form id="verifyReportForm" data-name="Email Form 3">
                                                    <input type="text" class="verify-form-inp" placeholder="Verify your Report" name="sumid" value="14J9915472219" />
                                                </form>
                                                <span class="search-icn" style={{ marginLeft: "80px" }} onClick={() => setShow(!show)}>
                                                    <i id="SearchIcon" class="scroll-Report fa fa-times" aria-hidden="true"></i>

                                                </span>
                                            </div>
                                            <div id="verifyReoprt" class="verify-form2">
                                                <form id="verifyReportForm" data-name="Email Form 3">
                                                    <input type="text" class="verify-form-inp" placeholder="Verify your Report" name="sumid" value="14J9915472214" />
                                                </form>
                                                <span class="search-icn" style={{ marginLeft: "80px" }} onClick={() => setShow(!show)}>
                                                    <i id="SearchIcon" class="scroll-Report fa fa-times" aria-hidden="true"></i>

                                                </span>
                                            </div>
                                            <div id="verifyReoprt" class="verify-form2">
                                                <form id="verifyReportForm" data-name="Email Form 3">
                                                    <input type="text" class="verify-form-inp" placeholder="Verify your Report" name="sumid" value="14J9915482206" />
                                                </form>
                                                <span class="search-icn" style={{ marginLeft: "80px" }} onClick={() => setShow(!show)}>
                                                    <i id="SearchIcon" class="scroll-Report fa fa-times" aria-hidden="true"></i>

                                                </span>
                                            </div> */}

                                        <button className='btn form-control mt-3 bg-dark text-white'>Preview</button>
                                    </div>


                                    {/* <Multiselect
                                        isObject={false}
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={(event) => { console.log(event) }}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={(event) => { console.log(event) }}
                                    options={[
                                        'Option 1',
                                        'Option 2',
                                        'Option 3',
                                        'Option 4',
                                        'Option 5'
                                      ]}
                                    /> */}

                                    <Select
                                        options={optionList}
                                        placeholder="Select color"
                                        value={selectedOptions}
                                        onChange={handleSelect}
                                        isSearchable={true}
                                        isMulti
                                    />


                                    <select isMulti className='form-control'>
                                        {details.map((d) => {
                                            return <option value={d._id}>{d.summaryNumber}</option>;
                                        })}
                                    </select>
                                </Card>
                            </div>
                            <div className='col-7'>
                                <div>
                                    {/* <ReactToPrint
                                        trigger={() => <a href="#">Print this out!</a>}
                                        content={() =>componentRef}
                                    />
                                    <ComponentToPrint ref={el => (componentRef = el)} /> */}
                                    <div>
                                        <Card className="cardstyle">
                                            {/* <div style={{ padding: "50px" }}> */}
                                            {/* <h6>Print Cards</h6><br />
                                   sfdgfdhg */}
                                            <div style={{ padding: "20px" }}>
                                                <div className='row' >
                                                    <div className='col'>
                                                        <img src={img1} style={{ width: "200px" }}></img>
                                                    </div>
                                                    <div className='col'>
                                                        <img src={img2} style={{ width: "200px" }}></img>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col'>
                                                        <img src={img3} style={{ width: "200px" }}></img>
                                                    </div>
                                                    <div className='col'>
                                                        <img src={img1} style={{ width: "200px" }}></img>
                                                    </div>
                                                </div>
                                                <div style={{ float: "right" }} className="mt-4">
                                                    <button className='btn btn-primary' >
                                                        Download
                                                    </button>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </Card>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>


                </Box>
            </Box>
        </div >



    )
}

// {details.map((d) => {
//     return <option value={d._id}>{d.sumid}</option>;
// })}


export default Cardprint